import createClient, { type Middleware } from 'openapi-fetch';
import type { paths } from '@nrjx/api';

export const useApiV2 = () => {
  const runtimeConfig = useRuntimeConfig();
  // const { $firebase } = useNuxtApp();

  // Get token synchronously to ensure it's available when making requests
  const store = firebaseStore();
  const token = store.getAccessToken;

  const authMiddleware: Middleware = {
    async onRequest({ request }) {
      request.headers.set('Authorization', `Bearer ${token}`);
      return request;
    },
    // To test for the refresh token, uncomment the following code
    // async onResponse({ request, response }) {
    //   if (response.status === 401) {
    //     // Try to refresh the token
    //     try {
    //       if ($firebase.currentUser) {
    //         // Force token refresh
    //         token = await $firebase.currentUser.getIdToken(true);
    //         store.accessToken = token;

    //         // Retry the original request with the new token
    //         const newRequest = new Request(request, {
    //           headers: new Headers(request.headers),
    //         });
    //         newRequest.headers.set('Authorization', `Bearer ${token}`);

    //         return fetch(newRequest);
    //       }
    //     } catch (error) {
    //       console.error('Failed to refresh token:', error);
    //       // If we can't refresh the token, we should probably logout the user
    //       await store.logout();
    //       return response;
    //     }
    //   }

    //   const { body, ...resOptions } = response;
    //   return new Response(body, { ...resOptions, status: response.status === 401 ? 401 : 200 });
    // },
  };

  const client = createClient<paths>({
    baseUrl: runtimeConfig.public.apiV2Url,
    headers: {},
  });
  client.use(authMiddleware);

  return {
    client,
  };
};
